import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { FormattedMessage } from "gatsby-plugin-intl"

import { CountUp } from "countup.js"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero"
import Demo from "../../components/demo"
import Testimonials from "../../components/testimonials"

import Check from "../../images/icons/check.svg";
import esLogo from "../../images/why-us/expertsender-logo.gif"

class SuccessStories extends Component {
  reveal () {
    const ScrollReveal = require('scrollreveal').default

    const counterOptions = {
      duration: 2,
      separator: ' ',
      suffix: '+'
    };

    ScrollReveal({
      viewFactor: 0.4,
      distance: '100px',
      duration: 1000
    });

    ScrollReveal().reveal('.sr-1', {
      delay: 0
    });

    ScrollReveal().reveal('.sr-2', {
      delay: 100
    });

    ScrollReveal().reveal('.sr-3', {
      delay: 200
    });

    ScrollReveal().reveal('.sr-r', {
      origin: 'right'
    });

    ScrollReveal().reveal('.sr-b', {
      origin: 'bottom'
    });

    ScrollReveal().reveal('.sr-q', {
      delay: 100,
      beforeReveal: function () {
        document.getElementsByClassName('sr-q')[0].classList.add('is-loaded');
      }
    });

    ScrollReveal().reveal('.sr-c-1', {
      delay: 0,
      beforeReveal: function () {
        const counterYears = new CountUp('counter-years', 11, counterOptions);
        counterYears.start();
      }
    });

    ScrollReveal().reveal('.sr-c-2', {
      delay: 100,
      beforeReveal: function () {
        const counterBrands = new CountUp('counter-brands', 1000, counterOptions);
        counterBrands.start();
      }
    });

    ScrollReveal().reveal('.sr-c-3', {
      delay: 200,
      beforeReveal: function () {
        const counterMails = new CountUp('counter-employee', 100, counterOptions);
        counterMails.start();
      }
    });

    ScrollReveal().reveal('.sr-c-4', {
      delay: 0,
      beforeReveal: function () {
        const counterYears = new CountUp('counter-satisfaction', 96, {
          duration: 2,
          suffix: '%'
        });
        counterYears.start();
      }
    });

    ScrollReveal().reveal('.sr-c-5', {
      delay: 100,
      beforeReveal: function () {
        const counterBrands = new CountUp('counter-delivered', 96.5, {
          duration: 2,
          decimalPlaces: 1,
          decimal: ',',
          suffix: '%'
        });
        counterBrands.start();
      }
    });

    ScrollReveal().reveal('.sr-c-6', {
      delay: 200,
      beforeReveal: function () {
        const counterMails = new CountUp('counter-mails', 400000000, counterOptions);
        counterMails.start();
      }
    });
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      this.reveal();
    }
  }

  render () {
    return (
      <Layout>
        <SEO title="Наш опыт" description="Наш опыт — развивайте бизнес, используя автоматизацию многоканального маркетинга, основанную на данных. Побеждайте конкурентов!" />
        <Hero title="Наш опыт" subtitle="Что делает нас надежным партнером в бизнесе?" />

        <div className="container container--pad pad-t-3 mar-t-6 mar-b-6">
          <div className="columns is-vcentered">
            <div className="column sr-hidden sr-1">
              <div className="section-h section-h--full">
                <Check />
                <h2 className="section-h__2 mar-t-2 mar-b-2">
                  <FormattedMessage id="experience.h_1" />
                </h2>
              </div>
            </div>
            <div className="column sr-hidden sr-2">
              <div className="section-feature__anim section-feature__anim--l">
                <div className="parallax parallax--circle" />
                <img src={esLogo} className="section-feature__photo" alt="ExpertSender logo" />
              </div>
            </div>
          </div>
        </div>

        <div className="container container--pad mar-t-6 mar-b-6">
          <div className="section-c columns mar-t-3">
            <div className="column sr-hidden sr-c-1">
              <div id="counter-years" className="section-c__val">&nbsp;</div>
              <div className="section-c__desc mar-t-1">
                <FormattedMessage id="experience.n_1" />
              </div>
            </div>
            <div className="column sr-hidden sr-c-2">
              <div id="counter-brands" className="section-c__val">&nbsp;</div>
              <div className="section-c__desc mar-t-1">
                <FormattedMessage id="experience.n_2" />
              </div>
            </div>
            <div className="column sr-hidden sr-c-3">
              <div id="counter-employee" className="section-c__val">&nbsp;</div>
              <div className="section-c__desc mar-t-1">
                <FormattedMessage id="experience.n_3" />
              </div>
            </div>
          </div>
        </div>

        <div className="container container--pad mar-t-6">
          <div className="section-c columns mar-t-3">
            <div className="column sr-hidden sr-c-4">
              <div id="counter-satisfaction" className="section-c__val">&nbsp;</div>
              <div className="section-c__desc mar-t-1">
                <FormattedMessage id="experience.n_4" />
              </div>
            </div>
            <div className="column sr-hidden sr-c-5">
              <div id="counter-delivered" className="section-c__val">&nbsp;</div>
              <div className="section-c__desc mar-t-1">
                <FormattedMessage id="experience.n_5" />
              </div>
            </div>
            <div className="column sr-hidden sr-c-6">
              <div id="counter-mails" className="section-c__val">&nbsp;</div>
              <div className="section-c__desc mar-t-1">
                <FormattedMessage id="experience.n_6" />
              </div>
            </div>
          </div>
        </div>

        <div className="container container--pad mar-b-6">
          <Testimonials />
        </div>

        <Demo props={this.props.data} />
      </Layout>
    )
  }
}

export default SuccessStories

export const pageQuery = graphql`
  query {
    esDashboard: file(relativePath: { eq: "expertsender-dashboard.png" }) {
      childImageSharp {
        fluid(maxWidth: 1260, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
